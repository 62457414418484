import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout/layout"
import favicon from "../../images/favicon.ico"

export default () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>
        Privacy Policy - EzriRx.com - Marketplace Platform for 65,000+
        Pharmaceuticals
      </title>
      <meta name="keywords" content="ezrirx, pharmacy purchasing platform" />
      <meta
        name="description"
        content="EzriRx privacy policy. Please read to learn more about how EzriRX can help pharmacies save an average of 30% on drug purchases from multiple suppliers."
      />
      <link rel="canonical" href="https://www.ezrirx.com/legal/privacy/" />
      <link rel="shortcut icon" type="image/png" href={`${favicon}`} />
    </Helmet>
    <Layout>
      <div className="body">
        <section className="hero-banner hero-banner--only-text" id="hero-about">
          <div className="contents">
            <div className="hero-banner__inner">
              <div className="hero-content">
                <h1>Privacy policy</h1>
              </div>
            </div>
          </div>
        </section>

        <section className="marketing-content">
          <div className="contents">
            <div className="single-content-text">
              <p>
                <i>Last updated: September 1, 2019</i>
              </p>

              <h4 style={{ marginBottom: "25px" }}>Introduction</h4>

              <p>
                <strong>Web Site Owner</strong>
              </p>
              <p>
                EzriRx, LLC, a limited liability company organized under the
                laws of the State of Delaware (“EzriRx” or “we” or “us”) is the
                owner of this web site, www.EzriRx.com (the “Web Site”). As set
                forth in detail in our Terms of Serves, the Web Site serves as a
                portal for users to engage in transactions and we provide
                services to facilitate those transactions (the “Services”). We
                can be contacted via mail at support@ezrirx.com, via phone at
                718-502-6610. This privacy policy discloses EzriRx’s information
                practices for the Web Site, including what type of personally
                identifiable information is requested in order to register and
                use the Web Site, how the information is used and with whom the
                information is shared. Note that other web sites or services
                that you access by or in connection with your use of the
                EzriRx.com Web Site may be governed by privacy policies
                containing different information practices than ours; you should
                review the privacy policies of those web sites to identify and
                learn of those practices).
              </p>

              <p>
                <strong>Overview</strong>
              </p>
              <p>
                Our privacy policy applies to information we collect when you
                use or access the Web Site, our applications, or if you just
                interact with us online. We may change this privacy policy from
                time to time. Whenever we make changes to this privacy policy,
                we will notify you by email (as listed in your account settings)
                prior to these changes becoming effective. We encourage you to
                review this privacy policy whenever you access the Web Site to
                stay informed about our information practices and the ways you
                can help protect your privacy.
              </p>

              <h4 style={{ marginBottom: "25px" }}>
                Collection of Information
              </h4>

              <p>
                <strong>Information You Provide to Us</strong>
              </p>
              <p>
                We collect information you provide directly to us. For example,
                we collect information when you participate in any interactive
                features of our Web Site, fill out a form, request customer
                support, provide any contact or identifying information or
                otherwise communicate with us. The types of information we may
                collect include your name, your company name, email address,
                postal address, user name, password, telephone number, facsimile
                number, credit card information and other contact or identifying
                information you choose to provide. We also collect information
                you upload to the Web Site including price files, offers,
                product information, transaction information, sales data and
                other information about your business and the transactions you
                engage in or attempt to engage in on our Web Site.
              </p>

              <p>
                <strong>Information We Collect Automatically</strong>
              </p>
              <p>
                When you access the Web Site or use our Services, we
                automatically collect information about you, including:
              </p>

              <ul>
                <li>
                  <strong>Log Information:</strong> We log information about
                  your use of the Web Site or our Services, including the type
                  of browser you use, access times, pages viewed, your IP
                  address and the page you visited before navigating to our
                  services.
                </li>
                <li>
                  <strong>Device Information:</strong> We collect information
                  about the computer you use to access our services, including
                  the hardware model, and operating system and version.
                </li>
                <li>
                  <strong>
                    Information Collected by Cookies and Other Tracking
                    Technologies:
                  </strong>{" "}
                  We use various technologies to collect information, and this
                  may include sending cookies to your computer. Cookies are
                  small data files stored on your hard drive or in your device
                  memory that helps us to improve our Services and your
                  experience, see which areas and features of our Services are
                  popular and count visits. We may also collect information
                  using web beacons (also known as "tracking pixels"). Web
                  beacons are electronic images that may be used in our services
                  or emails and to track count visits or understand usage and
                  campaign effectiveness.
                </li>
                <li>
                  <strong>Information We Collect From Other Sources:</strong> In
                  order to provide you with access to the Web Site and to
                  provide our Services, or to provide you with better service in
                  general, we may combine information obtained from other
                  sources (for example, a third-party service, including payment
                  processors, whose application you have authorized or used to
                  sign in) and combine that with information we collect through
                  our Services.
                </li>
                <li>
                  <strong>Information Provided to Third-Parties:</strong> Our
                  Web Site may contain links to, or require you to use, other
                  web sites or web-based services. We are not responsible for
                  the privacy practices or the content of those third-party
                  sites or services.
                </li>
              </ul>

              <p>
                For more details about how we collect information, including
                details about cookies and how to disable them, please see "Your
                Information Choices" below.
              </p>

              <h4 style={{ marginBottom: "25px" }}>Use of Information</h4>

              <p>
                We use the information we collect about you, or that you submit
                to us, for various purposes, including to:
              </p>

              <ul>
                <li>Provide, maintain and improve our Services;</li>
                <li>
                  Provide Services you request, process transactions and to send
                  you related information;
                </li>
                <li>
                  Send you technical notices, updates, security alerts and
                  support and administrative messages;
                </li>
                <li>
                  Respond to your comments, questions and requests and provide
                  customer service;
                </li>
                <li>
                  Communicate with you about news and information related to our
                  Services;
                </li>
                <li>
                  Monitor and analyze trends, usage and activities in connection
                  with our Services;
                </li>
                <li>Personalize and improve our services;</li>
                <li>
                  Provide you with information about our Services, other
                  services we may offer or plan to offer, or to send you
                  marketing materials from third-parties we think you may be
                  interested in; and
                </li>
                <li>
                  Any other commercial, advertising, data processing or other
                  purpose we choose in our sole discretion.
                </li>
              </ul>

              <p>
                By accessing and using our Web Site or Services, you consent to
                the processing, use and transfer of your information in and to
                the United States and other countries. We may interact with
                legal entities, business processes, management structures, and
                technical systems that cross borders. We may share your personal
                information within the EzriRx corporate organization, and we may
                transfer the information to countries in the world where our
                partners conduct business. Some countries may provide less legal
                protection for user personal information than the United States.
                In such countries EzriRx will still handle user personal
                information in the manner describe herein.
              </p>

              <h4 style={{ marginBottom: "25px" }}>Sharing of Information</h4>

              <p>We may share personal information about you as follows:</p>

              <ul>
                <li>
                  With third party vendors and other service providers who need
                  access to your information to carry out work on our behalf,
                  such as storing and verifying banking information;
                </li>
                <li>
                  If we believe disclosure is reasonably necessary to comply
                  with any applicable law, regulation, legal process or
                  governmental request;
                </li>
                <li>
                  To enforce applicable user agreements or policies, including
                  our <a href="/legal/terms/">Terms of Service</a>; and to
                  protect us, our users or the public from harm or illegal
                  activities;
                </li>
                <li>
                  In connection with any merger, sale of EzriRx assets,
                  financing or acquisition of all or a portion of our business
                  to another company;
                </li>
                <li>
                  Under certain circumstances, your personal information may be
                  subject to disclosure pursuant to judicial or other government
                  subpoenas, warrants or orders; and
                </li>
                <li>
                  If we notify you through our Services (or in our privacy
                  policy) that the information you provide will be shared in a
                  particular manner and you provide such information.
                </li>
              </ul>

              <p>
                We may also share aggregated or anonymized information that does
                not directly identify you.
              </p>

              <h4 style={{ marginBottom: "25px" }}>Third Party Analytics</h4>

              <p>
                We may allow third parties to provide analytics services. These
                third parties may use cookies, web beacons and other
                technologies to collect information about your use of the
                Services, the Web Site and other web sites, including your IP
                address, web browser, pages viewed, time spent on pages, links
                clicked and conversion information. This information may be used
                by us and third parties to, among other things, analyze and
                track data, determine the popularity of certain content and
                other web sites and to better understand your online activity.
                Our privacy policy does not apply to, and we are not responsible
                for, third party cookies, web beacons or other tracking
                technologies and we encourage you to check the privacy policies
                of these third parties to learn more about their privacy
                practices.
              </p>

              <h4 style={{ marginBottom: "25px" }}>Security</h4>

              <p>
                We take reasonable physical, electronic and managerial measures
                to help protect personal information from loss, theft, misuse
                and unauthorized access, disclosure, alteration and destruction.
                If a user’s password is lost or stolen, or if a user’s account
                is subject to unauthorized access, the user should promptly
                notify EzriRx to enable us to take appropriate action.
              </p>

              <p>
                EzriRx will exercise all reasonable efforts to safeguard the
                confidentiality of our users’ personal information. However,
                transmissions protected by industry standard security technology
                and implemented by human beings cannot be made absolutely
                secure. Consequently, EzriRx shall not be liable for
                unauthorized disclosure of personal information including, but
                not limited to, errors in transmission, and authorized acts of
                EzriRx employees, agents or third-parties.
              </p>

              <h4 style={{ marginBottom: "25px" }}>Your Information Choices</h4>

              <ul>
                <li>
                  <strong>Cookies:</strong> Most web browsers are set to accept
                  cookies by default. If you prefer, you can usually choose to
                  set your browser to remove or reject browser cookies. Please
                  note that if you choose to remove or reject cookies, this
                  could affect the availability and functionality of our
                  Services.
                </li>
                <li>
                  <strong>Promotional Communications:</strong> You may opt out
                  of receiving any promotional emails from us by following the
                  instructions in those emails. If you opt out, we may still
                  send you non-promotional communications, such as those about
                  your account or our ongoing business relations.
                </li>
              </ul>

              <p>
                By accessing our Web Site and using our Services, you expressly
                consent to receive promotional advertisements and other
                communications to any facsimile number you provide to us. You
                may opt out of receiving such communications by following the
                instructions on any facsimile you receive from us.
              </p>

              <h4 style={{ marginBottom: "25px" }}>Contact Us</h4>

              <p>
                If you have any questions about this privacy policy, please{" "}
                <a href="/contact/">contact us</a>.
              </p>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  </>
)
